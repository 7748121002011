<template>
    <div style="margin-bottom: 25px;">
        <div class="input-file flex justify-between items-center mb-0" :class="{ 'form-control' : !buttonOnly }">
            <label v-if="!buttonOnly" class="m-0 text-muted">{{ label }}</label>
            <div class="input-file-button">
                <input v-if="!readOnly" type="file" @change="onFileSelected" accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" :required="isRequired">
                <button v-if="!readOnly" class="btn btn-primary">{{ buttonLabel }}</button>
                <button v-else class=" btn btn-primary">View</button>
            </div>
        </div>
        <span v-if="showError" class="text-danger small">File harus diisi</span>
    </div>
</template>

<script>
    export default {
        props: {
            placeholder: {
                type: String,
                default: ''
            },
            value: {
                type: String,
                default: null
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            buttonOnly: {
                type: Boolean,
                default: false
            },
            buttonLabel: {
                type: String,
                default: 'Browse'
            },
            required: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            label () {
                if (this.selectedValue) return this.selectedValue
                if (this.value) return this.value
                return this.placeholder
            },
            isRequired () {
                if (!this.required) return false

                return true
            },
            showError () {
                if (!this.required) return false

                if (this.value || this.selectedValue) return false

                return true
            }
        },
        data () {
            return {
                selectedValue: ''
            }
        },
        methods: {
            onFileSelected (e) {
                const file = e.target.files[0]
                if (file) {
                    this.$emit('change', e.target.files[0])
                    this.selectedValue = file.name
                }
            }
        }
    }
</script>

<style>
    .input-file-button {
        position: relative;
        overflow: hidden;
    }

    .input-file-button,
    .input-file-button button {
        width: 200px;
        cursor: pointer;
    }

    .input-file-button input[type="file"] {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
    }
</style>
