<template>
    <div class="mcontainer height-screen">
        <bread-crumb :items="breadcrumbs" />

        <loading :loading="bimbinganLoading" spinner-class="justify-center">
            <div v-if="!showDetail" class="max-w-670">
                <div class="card p-3 mt-3">
                    <h5 class="font-bold color-dark">Judul Pembahasan</h5>
                    <input class="form-control mt-2 mb-1" :value="bimbingan.title" type="text" autocomplete="off" disabled>

                    <div class="form-group mt-2">
                        <label class="font-semibold">File</label><br>
                        <div class="form-control">
                            <a :href="bimbingan.file" target="_blank" class="text-dark hover:text-blue-600">{{ filename ? filename : '--' }}</a>
                        </div>
                    </div>

                    <h5 class="font-bold color-dark">Link Google Drive</h5>
                    <textarea class="form-control mt-2 mb-1" cols="30" rows="5" v-model="bimbingan.link" disabled></textarea>
                    <br>
                    <div v-for="(i, index) in bimbingan.mentors" :key="i.id" class="mb-3">
                        <h5 class="font-bold color-dark">Pembimbing {{ index + 1 }}</h5>
                        <p class="m-0">Status: <span class="color-dark font-bold" :class="getStatusClass(i.status)">{{ i.status_text }}</span></p>
                        <div v-if="i.note" class="flex form-control mt-2 note-wrapper">
                            <div class="label">
                                <p class="m-0">Catatan :</p>
                            </div>
                            <div class="ml-2 note">
                                <p class="m-0">{{ i.note }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4 flex w-full">
                        <a href="" v-if="bimbingan.status !== 1" @click.prevent="showDetail = true" class="btn btn-outline-secondary block mx-2 w-full">Lihat Detail</a>
                        <router-link :to="{ name: 'AjukanUlangDaftarBimbingan' }" class="btn btn-primary block mx-2 w-full" :class="{ disabled: !hasRejected }" :disabled="!hasRejected">Ajukan Ulang</router-link>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="mt-4">
                    <p>{{ bimbingan.title }}</p>
                </div>

                <b-row class="mt-2">
                    <b-col v-for="(i, index) in bimbingan.mentors" :key="i.id">
                        <div class="card p-3 text-center">
                            <div v-if="i.finished" class="mx-auto my-2">
                                <p class="m-0 font-bold">{{ formatDate(i.date) }}</p>
                                <p class="small">Approval</p>
                                <div class="circle-icon mx-auto mt-1" style="background-color: #1FB407">
                                    <Icon icon="mdi:check" class="text-lg" style="color: #fff" />
                                </div>
                            </div>
                            <div v-else class="mx-auto my-2">
                                <p class="m-0 font-bold">{{ formatDate(i.date) }}</p>
                                <p class="small">Approval</p>
                                <div class="mt-3 mb-2">
                                    <a href="javascript:void(0)" class="btn-binmbingan-status-reject mt-1">Belum Disetujui</a>
                                </div>
                            </div>

                            <p class="small">Pembimbing {{ index + 1 }}</p>
                            <p class="font-bold">{{ i.fullname }}</p>
                        </div>
                    </b-col>
                </b-row>

                <div class="card p-3 mt-4">
                    <h5 class="font-bold text-lg color-dark">Link & File</h5><br>
                    <div v-html="bimbingan.link" style="white-space: break-spaces;" v-linkified></div><br>
                    <div class="flex justify-between items-center">
                        <div class="flex align-items-center">
                            <Icon icon="mdi:file-document-multiple-outline" class="text-xl mr-2" />
                            <h5>
                                {{ filename }}
                            </h5>
                        </div>
                        <input-file :placeholder="filename" @change="changeFile" :button-only="true" button-label="Change File" />
                    </div>
                </div>

                <div class="card p-3 mt-4 overflow-visible">
                    <h5 class="font-bold text-lg color-dark">Notes</h5>

                    <comment-list
                        :comments="bimbingan.comments"
                        :comments-count="bimbingan.comments_count"
                        :disable-comment="0"
                        :add-comment="onComment"
                        :input-id="`bimbingan-note-${bimbingan.id}`"
                        :note-layout="true"
                    />
                </div>
            </div>
        </loading>
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import CommentList from '@/components/CommentList'
    import InputFile from '@/components/InputFile'
    import { mapState, mapActions } from 'vuex'
    import { Icon } from '@iconify/vue2'

    export default {
        components: {
            BreadCrumb,
            CommentList,
            Icon,
            InputFile
        },
        computed: {
            ...mapState('skripsi', { data: 'data', loading: 'loading' }),
            ...mapState('auth', { user: 'user' }),
            breadcrumbs () {
                return [
                    {
                        route: 'PengajuanBimbingan',
                        label: 'Pengajuan Bimbingan',
                        active: true
                    },
                    {
                        route: 'DetailBimbingan',
                        label: 'Detail Bimbingan'
                    }
                ]
            },
            filename () {
                return this.bimbingan && this.bimbingan.file ? this.bimbingan.file.split('/').pop() : ''
            },
            hasRejected () {
                var hasRejected = false

                if (this.bimbingan) {
                    this.bimbingan.mentors.forEach(mentor => {
                        if (mentor.status === 3) {
                            hasRejected = true
                        }
                    })
                }

                return hasRejected
            }
        },
        data () {
            return {
                bimbingan: {
                    mentors: []
                },
                form: {
                    data: {
                        skripsi_id: '',
                        title: '',
                        link: '',
                        mentors: []
                    },
                    loading: false
                },
                formApproval: {
                    data: {
                        location: ''
                    },
                    loading: false
                },
                showDetail: false,
                bimbinganLoading: false
            }
        },
        methods: {
            ...mapActions('skripsi', {
                createBimbingan: 'createBimbingan',
                updateBimbingan: 'updateBimbingan',
                fetch: 'fetch',
                showBimbingan: 'showBimbingan',
                addComment: 'addComment',
                approveJadwalBimbingan: 'approveJadwalBimbingan'
            }),
            getStatusClass (status) {
                switch (status) {
                    case 1:
                        return 'text-dark'
                    case 2:
                        return 'text-success'
                    case 3:
                        return 'text-danger'
                }
            },
            fetchSkripsi () {
                this.fetch()
                    .then(res => {
                        this.form.data.skripsi_id = res.id
                        if (res.mentors) {
                            res.mentors.forEach(mentor => {
                                this.form.data.mentors.push({
                                    id: mentor.id,
                                    date: null,
                                    time: null
                                })
                            })
                        }
                    })
            },
            changeFile (file) {
                this.bimbinganLoading = true
                this.updateBimbingan({
                    id: this.bimbingan.id,
                    data: {
                        file
                    }
                })
                    .then(() => {
                        return this.fetchBimbingan()
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        this.bimbinganLoading = false
                    })
            },
            approve () {
                this.approveJadwalBimbingan({
                    id: this.bimbingan.id,
                    data: {
                        location: this.formApproval.data.location
                    }
                }).then(() => {
                    this.$swal({
                        imageUrl: require('@/assets/images/bimbingan-approved.png'),
                        imageWidth: 400,
                        title: 'Jadwal Disetujui',
                        text: 'Terima kasih telah menyetujui jadwal yang di ajukan'
                    })
                    this.$router.push({ name: 'JadwalBimbingan' })
                }).catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                })
            },
            submit () {
                this.form.loading = true
                this.createBimbingan(this.form.data)
                    .then(() => {
                        this.$swal({
                            imageUrl: require('@/assets/images/pengajuan-bimbingan.png'),
                            imageWidth: 400,
                            title: 'Pengajuan Bimbingan',
                            text: 'Pengajuan anda sudah berhasil, tunggu konfirmasi selanjutnnya'
                        })
                        this.fetchSkripsi()
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something Wrong'
                        })
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            },
            onDateSelected (id, val) {
                const temp = []

                this.form.data.mentors.forEach(mentor => {
                    if (mentor.id === id) {
                        temp.push({
                            id: mentor.id,
                            date: this.formatDateStore(val),
                            time: mentor.time
                        })
                    } else {
                        temp.push(mentor)
                    }
                })

                this.form.data.mentors = temp
            },
            onTimeSelected (id, val) {
                const temp = []

                this.form.data.mentors.forEach(mentor => {
                    if (mentor.id === id) {
                        temp.push({
                            id: mentor.id,
                            date: mentor.date,
                            time: val.target.value
                        })
                    } else {
                        temp.push(mentor)
                    }
                })

                this.form.data.mentors = temp

                console.log(this.form.data.mentors)
            },
            addMentor () {
                this.form.data.mentors.push(0)
            },
            removeMentor (index) {
                this.form.data.mentors.splice(index, 1)
            },
            onComment (data) {
                return this.addComment({ ...data, id: this.bimbingan.id })
            },
            fetchBimbingan () {
                this.bimbinganLoading = true
                this.showBimbingan(this.$route.params.id)
                    .then(res => {
                        if (res.status === 2) {
                            this.showDetail = true
                        }
                        this.bimbingan = res
                    })
                    .catch(e => {
                        console.log(e)
                    })
                    .finally(() => {
                        this.bimbinganLoading = false
                    })
            }
        },
        mounted () {
            this.fetchSkripsi()
            this.fetchBimbingan()
        }
    }
</script>
