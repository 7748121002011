<template>
    <ul class="breadcrumb">
        <li v-for="i in items" class="breadcrumb-item" :class="{ active: i.active }" :key="i.route">
            <router-link :to="{ name: i.route }">{{ i.label }}</router-link>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                default: () => []
            }
        }
    }
</script>
